import { template as template_b39b5607b130422e8fdc017b49076f8b } from "@ember/template-compiler";
const FKLabel = template_b39b5607b130422e8fdc017b49076f8b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
