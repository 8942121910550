import { template as template_6a072eb0f2b04b78a30d2888ad3a9bdf } from "@ember/template-compiler";
const FKText = template_6a072eb0f2b04b78a30d2888ad3a9bdf(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
